
export const state = () => ({
    VirtualChannel: [],

    AccountVirtualWallet: [], //會員的銀行卡列表(無分類)
    AccountVirtualWalletCount: 0,
    AccountVirtualWalletLimit: 0,
})

export const getters = {
    VirtualChannel: state => {
        return state.VirtualChannel.map(item => {
            return { value: item.id, text: item.address_name }
        })
    },
    AccountVirtualWallet: state => {
        let classArr = ['credit ing', 'credit pass', 'credit returns'];
        let passArr  = ['store.under_review', 'store.examination_passed', 'store.review_returns'];
        let iconArr  = ['icon icon-review', 'icon icon-ok', 'icon icon-infomation'];
        return state.AccountVirtualWallet.map((item, index) => {
            return {
                id: item.id,
                bank_account: item.bank_account,
                address_name: item.address_name,
                wallet_address: item.wallet_address,
                wallet_channel: item.wallet_channel,
                flag: item.flag,
                card_image: item.card_image,
                disabled: item.flag == 0,
                class: Object.keys(classArr).indexOf(item.flag) >= 0 ? classArr[item.flag] : 'credit ing',
                pass: Object.keys(passArr).indexOf(item.flag) >= 0 ? passArr[item.flag] : 'store.under_review',
                icon: Object.keys(iconArr).indexOf(item.flag) >= 0 ? iconArr[item.flag] : 'icon icon-review'
            }
        })
    },
    AccountVirtualWalletGroup: state => (id) => {
        let Data = [];
        if (state.AccountVirtualWallet.length > 0) {
            state.AccountVirtualWallet.forEach(element => {
                let objKey   = `wallet_channel_${element.wallet_channel}`;
                let disabled = id != null && element.wallet_channel != id ? true : false;
                if (element.flag == 0)
                    disabled = true;

                if (Object.keys(Data).indexOf(objKey) < 0)
                    Data[objKey] = { label: element.address_name, options: [] }

                Data[objKey].options.push({ text: `${element.bank_account}(${element.address_name})`, value: element.id, disabled });
            });
        }
        return Object.keys(Data).map(key => { return Data[key] });
    }
}

export const mutations = {
    _setVirtualChannel: (state, data) => { state.VirtualChannel = data; },
    SetAccountVirtualWallet: (state, data) => {
        state.AccountVirtualWallet = data;
    },
   SetAccountVirtualWalletCount: (state, data) => {
        state.AccountVirtualWalletCount = data.count;
        state.AccountVirtualWalletLimit = data.limit;
    },
}

export const actions = {
    async _getVirtualChannel({ rootState, commit, dispatch }) {
        if (!rootState.NeedGetData.VirtualChannel) return
        const response = await this.$axios.post('/api/GetVirtualChannel');
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) {
                commit('_setVirtualChannel', response.data.list);
                commit('_setNeedGetData', { name: 'VirtualChannel', type: false }, { root: true });
            }
            return res
        })
    },
    async GetAccountVirtualWallet({ commit, dispatch }, data) {
        const response = await this.$axios.post('/api/VirtualWallet/List', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            commit('SetAccountVirtualWallet', response.data);
            if (data.check === true && response.data.length == 0) {
                this._vm.$bvModal.msgBoxOk(this.app.i18n.t('store.bind_bankcard'), {
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                }).then(value => {
                    this.$router.replace({ path: '/user/card' })
                }).catch(err => {

                })
            }
            return res;
        })
    },
    async GetAccountVirtualWalletCount({ commit, dispatch }, data) {
        const response = await this.$axios.post('/api/VirtualWallet/Count', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) {
                commit('SetAccountVirtualWalletCount', response.data);
            }
            return res
        })
    },
    async InsertAccountVirtualWallet({ dispatch }, data) {
        const response = await this.$axios.post('/api/VirtualWallet/Insert', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            return res
        })
    }
}
