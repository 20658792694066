import { setToken } from '~/utils/token'

export const state = () => ({
    AccountLineCode: null, //Line綁定的code
})

export const getters = {
}

export const mutations = {
    _setLineCode: (state, data) => {
        state.AccountLineCode = data;
    }
}

export const actions = {
    async CheckLogin({ dispatch }, { type, data }) {
        const response = await this.$axios.post(`/api/Login/Check/${type}`, data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            return response;
        })
    },
    async UnbindLogin({ dispatch }, { type, data }) {
        const response = await this.$axios.post(`/api/Login/Unbind/${type}`, data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            return res;
        });
    },
}