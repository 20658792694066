export const state = () => ({
  ProductId: null,
  ClassificationId: null,
  GameSelect: "",

  GameClassification: [],
  GameList: [],
  TryGame: false,
  TableSetting: {
    Loading: true,
    PageSetting: { Total: 1, Current: 1, PageSize: 12 }
  },
  GameLoading: false,
  PlayGameData: null,

  ProductList: [], //遊戲總類
});

export const getters = {
  GameClassification: state => {
    let Data = [];
    state.GameClassification.forEach(element =>
      Data.push({
        value: element.classification_id,
        text: element.name,
        total: element.total
      })
    );
    return Data;
  },
  GameList: (state, getters, rootState, rootGetters) => {
    const Device = rootState.IsMobile === false ? 1 : 2;
    return state.GameList.map((item, index) => {
      let data = item;
      data.className   = 'game-group';
      data.tryGameUrl  = `/api/TryGame/${item.id}?Device=${Device}`;
      data.playGameUrl = `/api/PlayGame/${item.id}?Device=${Device}`;
      data.hasTryGame  = data.try_game || false;
      return data;
    });
  },
  ProductList: (state) => {
    let Data = [];
    state.ProductList.forEach(element =>
      Data.push({value: element.id, text: element.name })
    );
    return Data;
  }
};

export const mutations = {
  _setProductList: (state, data) => {
      state.ProductList = data;
  },
  setProductId: (state, data) => {
    state.ProductId = data;
  },
  setClassificationId: (state, data) => {
    state.ClassificationId = data;
  },
  setGameSelect: (state, data) => {
    state.GameSelect = data;
  },
  setTableSettingLoading: (state, data) => {
    state.TableSetting.Loading = data;
  },
  setTableSettingTotal: (state, data) => {
    state.TableSetting.PageSetting.Total = data;
  },
  setTableSettingCurrent: (state, data) => {
    state.TableSetting.PageSetting.Current = data;
  },
  setTableSettingPageSize: (state, data) => {
    state.TableSetting.PageSetting.PageSize = data;
  },
  setTableSetting(state, payload) {
    state.TableSetting = Object.assign({}, state.TableSetting, payload);
  },
  setGameLoading: (state, data) => {
    state.GameLoading = data;
  },
  setGameClassification: (state, data) => {
    state.GameClassification = data;
  },
  setGameList: (state, data) => {
    state.GameList    = data.list;
    state.TryGame     = data.tryGame;
  },
  setPlayGameData: (state, data) => {
    state.PlayGameData = data;
  }
};

export const actions = {
  async _getProductList({ rootState, commit, dispatch }) {
      if (!rootState.NeedGetData.ProductList) return
      const response = await this.$axios.post('/api/GetProductList');
      return dispatch('_responseStatus', response, { root: true }).then((res) => {
          if (res) {
              commit('_setProductList', response.data.list);
              commit('_setNeedGetData', { name: 'ProductList', type: false }, { root: true });
          }
          return res
      })
  },
  async AccountTransfer({ dispatch }, data) {
    const response = await this.$axios.post("/api/Transaction/AccountTransfer", data);
    return dispatch("_responseStatus", response, { root: true }).then(res => {
      return res;
    });
  },
  async AllAccountTransfer({ dispatch }, data) {
    const response = await this.$axios.post("/api/Transaction/AllAccountTransfer",data);
    return dispatch("_responseStatus", response, { root: true }).then(res => { return res; });
  },
  async getTryGame({ rootState, dispatch }, data) {
    const Data = { Device: (rootState.IsMobile === false ? 1 : 2) };
    const response = await this.$axios.post(`/api/TryGame/${data}`, Data);
    return dispatch("_responseStatus", response, { root: true }).then(res => {
      return res ? response.data : res;
    });
  },
  async getPlayGame({ rootState, dispatch }, data) {
    const Data = { Device: (rootState.IsMobile === false ? 1 : 2) };
    const response = await this.$axios.post(`/api/PlayGame/${data}`, Data);
    return dispatch("_responseStatus", response, { root: true }).then(res => {
      return res ? response.data : res;
    });
  },
  async getGameClassification({ dispatch, commit, state }) {
    const Data = { ProductId: state.ProductId };
    const response = await this.$axios.post(
      "/api/Game/GameClassification",
      Data
    );
    return dispatch("_responseStatus", response, { root: true }).then(res => {
      if (res) commit("setGameClassification", response.data);
      return res;
    });
  },
  async getGameList({ rootState,dispatch, commit, state }) {
    if (rootState.IsMobile === false && state.TableSetting.PageSetting != 18)
      commit("setTableSettingPageSize", 18);

    commit("setGameLoading", true);
    const Data = {
      ProductId: state.ProductId,
      ClassificationId: state.ClassificationId,
      PageSetting: state.TableSetting.PageSetting,
      GameSelect: state.GameSelect
    };

    const response = await this.$axios.post("/api/Game/GameList", Data);
    return dispatch("_responseStatus", response, { root: true }).then(res => {
      if (res) {
        commit("setGameList", response.data);
        commit("setTableSettingTotal", response.data.total);
      }

      commit("setGameLoading", false);
      return res;
    });
  }
};
