const middleware = {}

middleware['activity'] = require('../middleware/activity.js')
middleware['activity'] = middleware['activity'].default || middleware['activity']

middleware['checkBankCard'] = require('../middleware/checkBankCard.js')
middleware['checkBankCard'] = middleware['checkBankCard'].default || middleware['checkBankCard']

middleware['checkLogin'] = require('../middleware/checkLogin.js')
middleware['checkLogin'] = middleware['checkLogin'].default || middleware['checkLogin']

middleware['checkNoLogin'] = require('../middleware/checkNoLogin.js')
middleware['checkNoLogin'] = middleware['checkNoLogin'].default || middleware['checkNoLogin']

middleware['device'] = require('../middleware/device.js')
middleware['device'] = middleware['device'].default || middleware['device']

middleware['getIp'] = require('../middleware/getIp.js')
middleware['getIp'] = middleware['getIp'].default || middleware['getIp']

middleware['i18n'] = require('../middleware/i18n.js')
middleware['i18n'] = middleware['i18n'].default || middleware['i18n']

middleware['login'] = require('../middleware/login.js')
middleware['login'] = middleware['login'].default || middleware['login']

middleware['placement'] = require('../middleware/placement.js')
middleware['placement'] = middleware['placement'].default || middleware['placement']

middleware['seo'] = require('../middleware/seo.js')
middleware['seo'] = middleware['seo'].default || middleware['seo']

middleware['slideshow'] = require('../middleware/slideshow.js')
middleware['slideshow'] = middleware['slideshow'].default || middleware['slideshow']

middleware['webSetting'] = require('../middleware/webSetting.js')
middleware['webSetting'] = middleware['webSetting'].default || middleware['webSetting']

export default middleware
