<template>
  <div class="error">
    <div class="line"></div>
    <div class="moon"></div>

    <div class="rocket"></div>
    <div class="satellite"></div>
    <div class="man">
      <div class="manimg"></div>
    </div>
    <div class="error-nofound" v-if="error.statusCode === 404">
      <h1>404</h1>
      <h3>OOPS!</h3>
      <p>{{ $t("store.error_sorry") }}</p>
    </div>
    <div class="error-nofound" v-else>
      <h1>404</h1>
      <h3>OH!NO!</h3>
      <p>{{ $t("store.error_sorry") }}</p>
    </div>
    <nuxt-link class="btn btn-link" to="/">{{
      $t("store.error_back_home")
    }}</nuxt-link>
  </div>
</template>

<script>
export default {
  props: ["error"],
  data() {
    return {
      sorry: this.$t("store.error_sorry"),
      back_home: this.$t("store.error_back_home"),
    };
  },
};
</script>
