
export const state = () => ({
    DiscountList: [],
    DiscountTableSetting: { Loading: true, PageSetting: { Total: 1, Current: 1, PageSize: 10 } },
})

export const getters = {
}

export const mutations = {
    setTableList: (state, { name, data }) => {
        state[`${name}List`] = data.list;
        state[`${name}TableSetting`].PageSetting.Total = data.total
    },
    setTableLoading: (state, { name, value }) => {
        state[`${name}TableSetting`].Loading = value;
    },
    setTablePage: (state, { name, type, num }) => {
        state[`${name}TableSetting`][type] = num;
    },
}

export const actions = {
    async getDiscountList({ state, dispatch, commit }, SelectForm) {
        let name = 'Discount';
        commit('setTableLoading', { name, value: true});
        let PageSetting = state.DiscountTableSetting.PageSetting;
        const response = await this.$axios.post('/api/Discount/List', { SelectForm, PageSetting });
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) commit('setTableList', { name, data: response.data });
            commit('setTableLoading', { name, value: false});
            return res
        })
    },
    async insertDiscount({ rootState, dispatch, commit }, data) {
        const response = await this.$axios.post('/api/Transaction/AccountDiscount', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            return res
        })
    },
}