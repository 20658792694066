const UuidKey = 'WUUID';

export function getUuid() {
	if (!_testLocalStorage())
		return '';

	if (localStorage.getItem(UuidKey) == null || localStorage.getItem(UuidKey) == '')
		localStorage.setItem(UuidKey, _getUuid());

	return localStorage.getItem(UuidKey);
}

function _testLocalStorage() {
	if (typeof window == 'undefined')
		return false;
	if (typeof window.localStorage == 'undefined')
		return false;

	return true;
}

function _getUuid() {
  var d = Date.now();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now();
  }

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

// window.localStorage
//localStorage.setItem('key', 'value1');
//localStorage.getItem('Key')