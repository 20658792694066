import { getUuid } from '~/utils/uuid';

const strategy = 'local'
let isLock = false;
let refreshSubscribers = [];

export default function ({ $axios, redirect, store, app }) {

    $axios.defaults.validateStatus = function (status) {
        return status <= 500
    }

    $axios.interceptors.request.use(
  		config => {
            const { $axios, $auth } = app
            let Token = $auth.getToken(strategy)
            if (Token && $auth.$state.loggedIn === true) {
                const tokenParsed = decodeToken.call(this, Token)
                if (isTokenExpired(tokenParsed.nbf * 1000)) {
                    if (!isLock) {
                        isLock = true;
                        return new Promise((resolve, reject) => {
                            refreshToken(config, Token, resolve, reject, store, $auth)
                        }).catch(err => {
                            reject(err);
                        })
                    } else {
                        if (config.url.indexOf('/api/refresh') === -1 && config.url.indexOf('/api/logout') === -1) {
                            let retry = new Promise((resolve, reject) => {
                                subscribeTokenRefresh((token) => {
                                    config.headers.Authorization = token
                                    resolve(config)
                                })
                            }).catch(err => {
                                reject(err);
                            })

                            return retry
                        } else {
                            return config;
                        }
                    }
                }
            }
                
            config.headers.WUUID   = getUuid();
            config.headers.Browser = store.state.BrowserKey;
            config.headers.Locale  = app.i18n.locale;
            return config;
        },
    )

	$axios.interceptors.response.use(
	    /**
	     * Determine the request status by custom code
	     * Here is just an example
	     * You can also judge the status by HTTP Status Code
	     */
    	response => {
      		const res = response.data;

            switch (response.status) {
                case 200: // 成功
                    break;
                case 401: //token 失效
                    const { $auth } = app
                    if ($auth.$state.loggedIn === true) {
                        store.dispatch('_showMsgBox', {title: '', msg: app.i18n.t('store.login_invalid')});
                        app.$auth.logout().then(() => { location.reload(); });
                    }
                    break;
                case 403: // Forbidden
                    store.dispatch('_showMsgBox', {title: '', msg: app.i18n.t('store.no_operating_authority')});
                    break;
                case 404: // Method Not Allowed
                case 405: // Method Not Allowed
                case 500: // Internal Server Error
                    store.dispatch('_showMsgBox', {title: '', msg: app.i18n.t('store.failed_request_plz_try_again')});
                    break;
                case 413:
                    store.dispatch('_showMsgBox', {title: '', msg: app.i18n.t('store.upload_limit_over')});
                    break;
                case 422: // Unprocessable Entity
                    store.dispatch('_showMsgBox', {title: '', msg: Object.values(response.data.errors).join("<br/>")});
                    break;
                default:
                    if (typeof res == 'object')
                        res.status = response.status
                    else
                        console.log(res)
                    break;
            }
      		return res
    	},
    	error => {
      		console.log('err ' + error) // for debug
      		return { status: 'err', msg: ''}
    	}
  	)

  	$axios.onError(
  		error => {
	    	const code = parseInt(error.response && error.response.status)
	    	if (code === 400) {
	      		// redirect('/404')
                console.log('code    ' + code)
	    	} else if (code === 500) {
	      		// redirect('/500')
                console.log('code    ' + code)
	    	}
  		}
  	)
}


function decodeToken (str) {
    str = str.split('.')[1]

    str = str.replace('/-/g', '+')
    str = str.replace('/_/g', '/')
    switch (str.length % 4) {
    case 0:
        break
    case 2:
        str += '=='
        break
    case 3:
        str += '='
        break
    default:
        throw new Error('Invalid token')
    }

    str = (str + '===').slice(0, str.length + (str.length % 4));
    str = str.replace(/-/g, '+').replace(/_/g, '/');
    str = decodeURIComponent(escape(Buffer.from(str, 'base64').toString('binary')));
    str = JSON.parse(str);
    return str;
}

function isTokenExpired(TokenExpires) {
    let Timestamp   = Math.round(new Date().getTime());
    let Expires     = Number(TokenExpires)
    if (Timestamp - Expires > 30 * 60 * 1000 && Timestamp - Expires < 60 * 60 * 1000) {
        return true;
    } else {
        return false;
    }
}

function subscribeTokenRefresh(cb) {
    refreshSubscribers.push(cb)
}

function onRrefreshed(token) {
    refreshSubscribers.map(cb => cb(token))
}

async function refreshToken(config, Token, resolve, reject, store, $auth) {
    store.dispatch('RefreshToken').then(res => {
        isLock = false;
        if (res) {
            config.headers.Authorization = $auth.getToken(strategy);
            resolve(config);
            onRrefreshed($auth.getToken(strategy))
            refreshSubscribers = []
        } else {
            refreshSubscribers = []
            $auth.logout().then(() => { location.reload(); });
            reject('');
        }
    }).catch(err => {
        reject(err);
    })
}