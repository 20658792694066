export const state = () => ({
    dataSheet: [], //簽到表
    dataSheetItem: [], //簽到項目
    haveSignQualify: [], //簽到完成資料存放
    errorCode: [], //無資格回傳的信息
    signinCount: null, // 累積次數
    loading: false,
    signStatus: false // 請求時確保只有 status 為 200 能簽到
})

export const mutations = {
    setDataSheet: (state, data) => {
        state.dataSheet = data
    },
    setDataSheetItem: (state, data) => {
        state.dataSheetItem = data
    },
    setHaveSignQualify: (state, data) => {
        state.haveSignQualify = data
    },
    setErrorCode: (state, data) => {
        state.errorCode = data
    },
    setLoading: (state, data) => {
        state.loading = data
    },
    setSigninCount: (state, data) => {
        state.signinCount = data
    },
    setSignStatus: (state, data) => {
        state.signStatus = data
    }
}

export const actions = {
    async getDataSheet({ commit }, id) {
        commit('setLoading', true);
        try {
            const response = await this.$axios.get(`/api/CheckInActivity/get/${id}`);
            
            if (response.status === 'ok') {
                commit('setErrorCode',[]);
                commit('setDataSheet', response.data);
                commit('setDataSheetItem', response.data.items);
            }
            else if (response.status === 'error') {
                commit('setErrorCode', { message: response.msg });
            }
            return response;
        } catch (error) {
            throw new Error('Error during dataSheet check: ', error);
        } finally {
            commit('setLoading', false);
        }
    },
    async getSigninCount({ commit }, id) {
        if (!this.$auth.$state.loggedIn) {
            return;
        }
        try {
            const response = await this.$axios.get(`/api/CheckInActivity/check-in/records/${id}`);
            
            if (response.status === 'ok') {
                commit('setSigninCount', response.data.order);
            }
            return response;
        } catch (error) {
            throw new Error('Error during signinCount check: ', error);
        }
    },
    async signin({ dispatch, commit }, id) {
        const account = this.$auth.$state.user.name;
        try {
            const response = await this.$axios.post(`/api/CheckInActivity/check-in/${id}`, { account });
            
            if (response.status === 'ok') {
                commit('setErrorCode',[]);
                commit('setSignStatus', true);
                commit('setHaveSignQualify', response.data);
                dispatch('getSigninCount', id);
            } else if (response.status === 'error') {
                if (!response.code) {
                    commit('setSignStatus', false);
                } else {
                    commit('setSignStatus', true);
                    commit('setErrorCode', { message: response.code });
                }
            }
            return response;
        } catch (error) {
            throw new Error('Error during signin check: ', error);
        } finally {
            commit('setSignStatus', false);
        }
    }
}