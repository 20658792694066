export const state = () => ({
  ProductId: null,
  GameLoading: false,
  GameSelect: false,

  GameClassificationId: {},
  GameClassificationList: {},

  GameSearchValue: {},
  GameTableSetting: {},
  GameList: {},

  PlayGameData: null,



  ClassificationId: null,  
  GameClassification: [],  
  TryGame: false,
  TableSetting: {
    Loading: true,
    PageSetting: { Total: 1, Current: 1, PageSize: 10 }
  },
  ProductList: [], //遊戲總類
});

export const getters = {
};

export const mutations = {
  setProductId: (state, data) => {
    state.ProductId = data;
  },
  
  setGameClassificationId: (state, { ProductId, Data }) => {
    state.GameClassificationId[`Product_${ProductId}`] = Data;
  },
  setGameClassificationList: (state, { ProductId, Data }) => {
    const GameClassification = [
      { value: 0, text: null, lang: 'store.all_games' }
    ];
    if (Data.length > 0)
      console.log(Data);

    state.GameClassificationList[`Product_${ProductId}`] = GameClassification;
  },
  setGameTableSetting: (state, { ProductId, Data }) => {
    state.GameTableSetting[`Product_${ProductId}`] = Data;
  },
  setGameSearchValue: (state, { ProductId, Data }) => {
    state.GameSearchValue[`Product_${ProductId}`] = Data;
  },
  setGameLoading: (state, data) => {
    state.GameLoading = data;
  },
  setGameSelect: (state, data) => {
    state.GameSelect = data;
  },

  setGameList: (state, { ProductId, Data, HasAdd, IsMobile }) => {
    let List = [];
    if (HasAdd && Object.keys(state.GameList).indexOf(`Product_${ProductId}`) >= 0)
      List = state.GameList[`Product_${ProductId}`];

    const Device = IsMobile === false ? 1 : 2;
    if (Object.keys(Data).indexOf('list') >= 0)
      Data.list.forEach(Item => {
        let Has = List.find(Element => Element.game_id == Item.game_id);
        if (!Has) {
          Item.tryGameUrl  = `/api/TryGame/${Item.id}?Device=${Device}`;
          Item.playGameUrl = `/api/PlayGame/${Item.id}?Device=${Device}`;
          Item.hasTryGame  = Item.try_game || false;
          List.push(Item);
        }
      });

    state.GameList[`Product_${ProductId}`] = List;
  },
  setPageSettingTotal: (state, { ProductId, Data, TableSetting }) => {
    let Total = [];
    if (Object.keys(Data).indexOf('total') >= 0)
      TableSetting.PageSetting.Total = Data.total;

    state.GameTableSetting[`Product_${ProductId}`] = TableSetting;
  },
  setPageSettingCurrent: (state, { ProductId, Data }) => {
    state.GameTableSetting[`Product_${ProductId}`].PageSetting.Current = Data;
  },

  setPlayGameData: (state, data) => {
    state.PlayGameData = data;
  }


  // setClassificationId: (state, data) => {
  //   state.ClassificationId = data;
  // },
  // setGameSearchValue: (state, data) => {
  //   state.GameSearchValue = data;
  // },
  // setTableSettingLoading: (state, data) => {
  //   state.TableSetting.Loading = data;
  // },
  // setTableSettingTotal: (state, data) => {
  //   state.TableSetting.PageSetting.Total = data;
  // },
  // setTableSettingCurrent: (state, data) => {
  //   state.TableSetting.PageSetting.Current = data;
  // },
  // setTableSettingPageSize: (state, data) => {
  //   state.TableSetting.PageSetting.PageSize = data;
  // },
  // setTableSetting(state, payload) {
  //   state.TableSetting = Object.assign({}, state.TableSetting, payload);
  // },
  
  
  // setGameList: (state, data) => {
  //   state.GameList    = data.list;
  //   state.TryGame     = data.tryGame;
  // },
  // setPlayGameData: (state, data) => {
  //   state.PlayGameData = data;
  // }
};

export const actions = {  
  async getGameClassificationId({ dispatch, commit, state }, ProductId) {
    commit("setGameClassificationId", { ProductId, Data: 0 });
  },  
  async getGameTableSetting({ dispatch, commit, state }, ProductId) {
    commit("setGameTableSetting", { ProductId, Data: {
      Loading: true,
      PageSetting: { Total: 1, Current: 1, PageSize: 12 }
    }});
  },
  async getGameSearchValue({ dispatch, commit, state }, ProductId) {
    commit("setGameSearchValue", { ProductId, Data: '' });
  },
  async getGameClassificationList({ dispatch, commit, state }, ProductId) {
    const Data     = { ProductId };
    const Response = await this.$axios.post('/api/Game/GameClassification', Data);
    return dispatch("_responseStatus", Response, { root: true }).then(Res => {
      if (Res)
        commit("setGameClassificationList", { ProductId, Data: Response.data });

      return Res;
    });
  },
  async getGameList({ rootState, dispatch, commit, state }, { ProductId, HasAdd, UseLoad }) {
    if (state.GameSelect === true)
      return;

    const Name = `Product_${ProductId}`;

    let TableSetting = state.GameTableSetting[Name];
    if (!TableSetting)
      TableSetting = { Loading: true, PageSetting: { Total: 1, Current: 1, PageSize: 12 }};

    if (rootState.IsMobile === false && TableSetting.PageSetting.PageSize != 18)
      TableSetting.PageSetting.PageSize = 18;

    const Data = {
      ProductId,
      ClassificationId:  state.GameClassificationId[Name],
      GameSearch: state.GameSearchValue[Name],
      PageSetting: TableSetting.PageSetting
    };

    if (UseLoad)
      commit("setGameLoading", true);
    
    commit("setGameSelect", true);
    
    const Response = await this.$axios.post("/api/Game/GameList", Data);
    return dispatch("_responseStatus", Response, { root: true }).then(Res => {
      if (Res) {
        commit("setGameList", { ProductId, Data: Response.data, HasAdd, IsMobile: rootState.IsMobile });
        commit("setPageSettingTotal", { ProductId, Data: Response.data, TableSetting });
      }

      commit("setGameLoading", false);
      commit("setGameSelect", false);      
      return Res;
    });
  }
};
