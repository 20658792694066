import { deviceType } from "~/utils/deviceType";

export default function (context) {
	if (process.server) {
		context.store.commit('_sethostName', `https://${context.req.headers.host}`)
		context.store.commit('_setfullPath', context.route.fullPath)
	}

	context.userAgent  = process.server ? context.req.headers["user-agent"] : navigator.userAgent;
	context.deviceType = deviceType(context.userAgent);

	// 這裡注入到store,是因為我部分頁面需要判斷機型請求不同的數據,
	// 你們沒有用到的話可以移除
	context.store.commit("_setDeviceType", context.deviceType);
	// 若是判斷UA非移動端的,就在這裡做處理了..
	// context.redirect(status,url) 這個可以重定向到外部網站
	// 若是內部訪問可以直接用router對象push

	let Path 		= context.route.path
	let NowIsMobile = /\/mobile/i.test(Path)
	let AgentCode 	= /\/a\//i.test(Path)
	let IsSignup 	= /\/singup/i.test(Path);
	let Redirect 	= null;

	if (context.deviceType.type === "pc") {
		if (NowIsMobile === true) {
			if (context.route.fullPath == '/mobile')
				Redirect = '/';
			else if (context.route.fullPath == '/mobile/wallet/hisDeposit')
				Redirect = '/history/hisDeposit';
			else if (context.route.fullPath == '/mobile/wallet/hisSale')
				Redirect = '/history/hisSale';
			else
				Redirect = context.route.fullPath.replace('/mobile', '/').replace('//', '/');
		}
	}
	else {
		if (NowIsMobile === false && AgentCode === false) {
			if (context.route.fullPath == '/history/hisDeposit')
				Redirect = '/mobile/wallet/hisDeposit';
			else if (context.route.fullPath == '/history/hisSale')
				Redirect = '/mobile/wallet/hisSale';
			else
				Redirect = '/mobile' + context.route.fullPath;
		}
	}

	if ([''].indexOf(process.env.LayoutsMobileTestStyle) >= 0) {
		if (NowIsMobile === true)
			Redirect = null;
		else if (NowIsMobile === false && AgentCode === false) {
			if (context.route.fullPath == '/history/hisDeposit')
				Redirect = '/mobile/wallet/hisDeposit';
			else if (context.route.fullPath == '/history/hisSale')
				Redirect = '/mobile/wallet/hisSale';
			else
				Redirect = '/mobile' + context.route.fullPath;
		}
	}

	if (IsSignup)
		context.redirect(301, context.route.fullPath.replace('singup', 'signup'));

	if (Redirect !== null)
		context.redirect(302, Redirect);

	return context.store.commit('_setIsMobile', context.deviceType.type !== 'pc')
}