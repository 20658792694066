import Cookies from 'js-cookie'

const Locales 	= ["tw", "en", "in"];
const LocaleKey = 'locale';

export function getLocale() {
	let Locale = '';
	if (process.browser && window.localStorage)
		Locale = localStorage.getItem(LocaleKey);
	else
		Locale = Cookies.get(LocaleKey);

	return Locale || false;
}

export function setLocale(Locale) {
  	if (process.browser && window.localStorage)
		localStorage.setItem(LocaleKey, Locale);

	Cookies.set(LocaleKey, Locale);
}

export function removeLocale() {
 	return Cookies.remove(LocaleKey);
}