
export const state = () => ({
    SlideshowList: []
})

export const getters = {
}

export const mutations = {
    setSlideshowList: (state, data) => {
        state.SlideshowList = data.list;
    }
}

export const actions = {
    async getSlideshowList({ rootState, dispatch, commit }) {
        if (!rootState.NeedGetData.SlideshowList) return;

        const response = await this.$axios.post('/api/Slideshow/List');
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) {
                commit('setSlideshowList', response.data);
                commit('_setNeedGetData', { name: 'SlideshowList', type: false }, { root: true });
            }
            return res;
        })
    },
}