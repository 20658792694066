
export const state = () => ({
    ActivityList: [],
    ActivityTabList: [],
})

export const getters = {
}

export const mutations = {
    setActivityList: (state, data) => {
        state.ActivityList = data.list;
    },
    setActivityTabList: (state, data) => {
        state.ActivityTabList = data.list;
    },
}

export const actions = {
    async getActivityList({ rootState, dispatch, commit }, data) {
        if (!rootState.NeedGetData.ActivityList) return;

        const response = await this.$axios.post('/api/Activity/List', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) {
                commit('setActivityList', response.data);
                commit('_setNeedGetData', { name: 'ActivityList', type: false }, { root: true });
            }
            return res;
        })
    },
    async getActivityTabList({ rootState, dispatch, commit }, data) {
        if (!rootState.NeedGetData.ActivityTabList) return;

        const response = await this.$axios.post('/api/GetDiscountActivityTab', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) {
                commit('setActivityTabList', response.data);
                commit('_setNeedGetData', { name: 'ActivityTabList', type: false }, { root: true });
            }
            return res;
        })
    },
}