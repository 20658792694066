import { deviceType } from "~/utils/deviceType";

export default ({ req, store }) => {
	if (process.server) {
		const ip = req.headers['x-real-ip'] || req.headers['x-forwarded-for'] || req.connection.remoteAddres || req.socket.remoteAddress || '';
		return store.commit('_setUserIp', ip)
  	}
  	if (process.client) {
    	// localStorage.setItem('ip', store.getters.ip)
    	// console.log(process.client)
  	}
}