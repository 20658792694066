import Cookies from 'js-cookie'

const TokenKey = 'token';

export function getToken() {
	const Token = Cookies.get(TokenKey)
	return Token || false
}

export function setToken(Token) {
	Cookies.remove(TokenKey);
  	return Cookies.set(TokenKey, Token, { expires: 1 })
}

export function removeToken() {
 	return Cookies.remove(TokenKey);
}