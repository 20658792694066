import { setToken } from '~/utils/token'
import { required, requiredIf, minLength, maxLength, alphaNum, numeric, email, sameAs } from "vuelidate/lib/validators";

export const state = () => ({
    AgentSign: [], //註冊欄位
})

export const getters = {
    AgentSign: state => {
        let data = []
        state.AgentSign.forEach((item, index) => {
            if (item.show == 1) data.push(item)
        })
        return data
    },
    AgentSignInfo: (state) => (field) => {
        return state.AgentSign.find(element => element.field === field)
    },
    AgentSignValidations: (state, getters, rootState, rootGetters) => {
        if (!state.AgentSign) return false

        let validate = {}
        state.AgentSign.forEach((item, index) => {
            if (item.show == 1) validate[item.field] = GetValidate(item, rootState.WebSetting)
        });

        if (rootGetters['webSetting/ShowAgentSignCaptchas'])
            validate['signcaptcha'] = { required }
        // if (rootGetters['webSetting/ShowAgentSignMobileCaptchas'])
        //     validate['usercaptcha'] = { required }

        return validate
    },
}

export const mutations = {
    _setAgentSign: (state, data) => {
        state.AgentSign = data;
    }
}

export const actions = {
    async _getAgentSign({ rootState, dispatch, commit }) {
        if (!rootState.NeedGetData.AgentSign) return
        const response = await this.$axios.post('/api/GetAgentSign');
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) {
                commit('_setAgentSign', response.data.list);
                commit('_setNeedGetData', { name: 'AgentSign', type: false }, { root: true });
            }
            return res
        })
    },
    async AgentInsert({ rootState, dispatch, commit }, data) {
        data.host = rootState.Host
        const response = await this.$axios.post('/api/Agent/Insert', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (response.status == 'ok') {
                // this.$cookies.remove('UpAgentCode')
                // commit('_setUpAgentCode', null, { root: true });
                // this.$auth.setUserToken(response.data).then(() => dispatch('GetAccountData'))
            }

            return res
        })
    },
}

function GetValidate(data, webSetting) {
    if (!data.field) return {}

    let Validate      = {}
    let AccountLen    = parseInt(Object.keys(webSetting).indexOf('ACCOUNT_LEN') >= 0 ? webSetting.ACCOUNT_LEN : 10);
    Validate.required = requiredIf(function () { return data.required > 0 });

    if (data.rule_front && Object.keys(data.rule_front).length > 0) {
        Object.keys(data.rule_front).forEach((item, index) => {
            switch (item) {
                case 'maxLength':
                    if (data.rule_front[item] == '{AccountLen}')
                        Validate.maxLength = maxLength(AccountLen);
                    else
                        Validate.maxLength = maxLength(data.rule_front[item]);
                    break;
                case 'minLength':
                    Validate.minLength = minLength(data.rule_front[item]);
                    break;
                case 'alphaNum':
                    Validate.alphaNum = alphaNum;
                    break;
                case 'numeric':
                    Validate.numeric = numeric;
                    break;
                case 'email':
                    Validate.email = email;
                    break;
                case 'sameAs':
                    Validate.sameAs = sameAs(data.rule_front[item]);
                    break;
            }
        });
    }

    return Validate
}