
export const state = () => ({
    QuizTeam: [],
    QuizList: []
})

export const getters = {
}

export const mutations = {
    setQuizTeam: (state, data) => {
        state.QuizTeam = data;
    },
    setQuizList: (state, data) => {
        state.QuizList = data.list.map(Item => {
            if (Item.id == 1) {
                let TmpQuestion = Item.question.map(Element => {
                    let TmpOptions = [];
                    Element.options.forEach((E, I) => {
                        if (Object.keys(TmpOptions).indexOf(E.group) < 0)
                            TmpOptions[E.group] = { group: E.group, team: []};

                        TmpOptions[E.group].team.push(E);
                    });
                    Element.options = Object.keys(TmpOptions).map(key => { return TmpOptions[key] });
                    return Element;
                });
                Item.question = TmpQuestion;
            }

            return Item;
        });
    }
}

export const actions = {
    async getQuizTeam({ state, dispatch, commit }, data) {
        const response = await this.$axios.post('/api/FIFA/Team', {});
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) commit('setQuizTeam', response.data);
            return res;
        })
    },
    async getQuizList({ state, dispatch, commit }, data) {
        const response = await this.$axios.post('/api/FIFA/List', {});
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) commit('setQuizList', response.data);
            return res;
        })
    },
    async insertFIFARecord({ state, dispatch, commit }, { id, form }) {
        const response = await this.$axios.post('/api/FIFA/Insert/' + id, form);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            return res;
        })
    },
    
}