export default function ({ store, app }) {

    app.router.beforeEach((to, from, next) => {
        let { $auth }   = app;
        let UpAgentCode = store.state.UpAgentCode;
        let ToQuery     = {};

        if (UpAgentCode && UpAgentCode != '' && !to.query.a && $auth.$state.loggedIn === false)
            ToQuery.a = UpAgentCode;
        if (Object.keys(from.query).indexOf('x') >= 0 && Object.keys(to.query).indexOf('x') < 0)
            ToQuery.x = null;

        if (Object.keys(ToQuery).length > 0) {
            next({
                path: to.path,
                query: {
                    ...to.query,
                    ...ToQuery,
                }
            });
        } else {
            next();
        }
    });
}