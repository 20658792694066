export default ({ app, store }) => {
	if (!process.browser)
		return;

	if (Object.keys(store.state.WebSetting).indexOf('WebSEO') < 0)
		return;

	if (Object.keys(store.state.WebSetting.WebSEO).indexOf('meta_pixel_id') < 0)
		return;

	if (!store.state.WebSetting.WebSEO.meta_pixel_id || store.state.WebSetting.WebSEO.meta_pixel_id == '')
		return;

	!(function(f,b,e,v,n,t,s) {
		if (f.fbq) return;
		n = f.fbq = function() { n.callMethod ? n.callMethod.apply(n,arguments) : n.queue.push(arguments) };
		if (!f._fbq) f._fbq = n;
		n.push = n;
		n.loaded = !0;
		n.version = '2.0';
		n.queue = [];
		t = b.createElement(e);
		t.async = !0;
		t.src = v;
		s = b.getElementsByTagName(e)[0];
		s.parentNode.insertBefore(t,s)
	})(window, document, 'script','https://connect.facebook.net/en_US/fbevents.js');
	
	fbq('init', store.state.WebSetting.WebSEO.meta_pixel_id);

	app.router.afterEach((to, from) => {
		fbq("track", "PageView");
	});
}