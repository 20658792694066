import Vue from "vue";
import VueI18n from "vue-i18n";
import customZhTW from '~/locales/zh-tw';
import customEnUS from '~/locales/en-us';
import customInHi from '~/locales/hi-in';
import { getLocale } from '~/utils/locale';
import { getJsonFile } from '~/utils/jsonFile';

Vue.use(VueI18n);

export default ({ app, store }) => {

  let customer = getJsonFile(app.context.env.DEF_webStyle, 'customer');
  if (Object.keys(customer).length > 0 && store.state.IsMobile) {
    customZhTW.store = Object.assign(customZhTW.store, customer);
  }

  let CustomerAll = getJsonFile(app.context.env.DEF_webStyle, 'customerAll');
  if (Object.keys(CustomerAll).length > 0) {
    let LocaleSingle       = 'single';
    let LocaleCombination  = 'combination';
    let CustomerAllKey     = Object.keys(CustomerAll);

    let LocaleNameArr      = ['ZhTW', 'EnUS', 'InHi'];
    LocaleNameArr.forEach((LocaleStr) => {
      if (CustomerAllKey.indexOf(LocaleStr) >= 0)
      {
        let CustomerAllKey = Object.keys(CustomerAll[LocaleStr]);
        if (CustomerAllKey.indexOf(LocaleSingle) >= 0)
        {
          if (LocaleStr == 'ZhTW')
            customZhTW.store = Object.assign(customZhTW.store, CustomerAll[LocaleStr][LocaleSingle]);
          if (LocaleStr == 'EnUS')
            customEnUS.store = Object.assign(customEnUS.store, CustomerAll[LocaleStr][LocaleSingle]);
          if (LocaleStr == 'InHi')
            customInHi.store = Object.assign(customInHi.store, CustomerAll[LocaleStr][LocaleSingle]);
        }
        if (CustomerAllKey.indexOf(LocaleCombination) >= 0)
        {
          let CustomerAllCombinationKey = Object.keys(CustomerAll[LocaleStr][LocaleCombination]);
          CustomerAllCombinationKey.forEach((item) => {
            if (LocaleStr == 'ZhTW')
              customZhTW.store[item] = Object.assign(customZhTW.store[item], CustomerAll[LocaleStr][LocaleCombination][item]);
            if (LocaleStr == 'EnUS')
              customEnUS.store[item] = Object.assign(customEnUS.store[item], CustomerAll[LocaleStr][LocaleCombination][item]);
            if (LocaleStr == 'InHi')
              customInHi.store[item] = Object.assign(customInHi.store[item], CustomerAll[LocaleStr][LocaleCombination][item]);
          });
        }
      }
    });

    // let LocaleName         = 'ZhTW';
    // if (CustomerAllKey.indexOf(LocaleName) >= 0) {
    //   let CustomerAllZhTWKey = Object.keys(CustomerAll[LocaleName]);
    //   if (CustomerAllZhTWKey.indexOf(LocaleSingle) >= 0)
    //     customZhTW.store = Object.assign(customZhTW.store, CustomerAll[LocaleName][LocaleSingle]);
    //   if (CustomerAllZhTWKey.indexOf(LocaleCombination) >= 0) {
    //     let CustomerAllZhTWcombinationKey = Object.keys(CustomerAll[LocaleName][LocaleCombination]);
    //     CustomerAllZhTWcombinationKey.forEach((item) => {
    //       customZhTW.store[item] = Object.assign(customZhTW.store[item], CustomerAll[LocaleName][LocaleCombination][item]);
    //     });
    //   }
    // }
  }

  let supportLang = ['zh-TW', 'en-US', 'hi-IN'];
  let localeArr = { 'tw': 'zh-TW', 'en': 'en-US', 'in': 'hi-IN' }
  let lang = getLocale() || app.$config.LOCALE || 'zh-TW';

  if (app.$config.PREFIX == 'tyc')
    lang = app.$config.LOCALE || 'zh-TW';

  if (supportLang.indexOf(lang) < 0)
    lang = Object.keys(localeArr).indexOf(lang) >= 0 ? localeArr[lang] : 'zh-TW';

  app.i18n = new VueI18n({
    locale: lang,
    fallbackLocale: lang,
    messages: {
      'zh-TW': customZhTW,
      'en-US': customEnUS,
      'hi-IN': customInHi
    }
  });

  app.i18n.path = link => {
    if (app.i18n.locale === app.i18n.fallbackLocale) {
      return `/${link}`;
    }

    return `/${app.i18n.locale}/${link}`;
  };
};
