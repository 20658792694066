
export const state = () => ({
    BankList: [], //銀行列表
    BankCardList: [], //
    AccountPayLocal: [],
    AccountPayThird: [],
    AccountWithdrawalData: {},
    AccountConversionData: {},
    AccountBankCard: [], //會員的銀行卡列表(銀行分類)
    AccountBankCardList: [], //會員的銀行卡列表(無分類)
    AccountBankCardCount: 0,
    AccountBankCardLimit: 0,
    AccountDiscountData: [],
    PayOtherList: [], //其他存款方式
    DepositMessage: {},
    DepositPage: {},
    TransferCaptchasImg: "",
})

export const getters = {
    BankList: state => {
        return state.BankList.map((item, index) => {
            return {value: item.id, text: `${item.name}(${item.code})`}
        })
    },
    BankCardList: state => {
        return state.BankCardList
    },
    CompanyBankCard: state => {
        let Data = [];
        Object.keys(state.AccountPayLocal).forEach(element => {
            Data.push({
                label: getPayTypeName(element),
                options: [{ text: state.AccountPayLocal[element].bank_name, value: state.AccountPayLocal[element].bank_card_id }]
            })
        });
        return Data
    },
    CompanyBankCardInfo: state => (value) => {
        let Data = [];
        if (Object.keys(state.AccountPayLocal).indexOf(value) >= 0)
            Data.push({ text: state.AccountPayLocal[value].bank_name, value: state.AccountPayLocal[value].bank_card_id })

        return Data
    },
    CompanyVirtualCurrencyInfo: state => (value, id) => {
        let Data = [];
        if (Object.keys(state.AccountPayLocal).indexOf(value) >= 0) {
            state.AccountPayLocal[value].forEach(element => {
                let objKey = `wallet_channel_${element.wallet_channel}`;
                let disabled = id != null && element.wallet_channel != id ? true : false;

                if (Object.keys(Data).indexOf(objKey) < 0)
                    Data[objKey] = { label: element.address_name, options: []}

                Data[objKey].options.push({ text: element.bank_name, value: element.bank_card_id, disabled });
            });
        }
        return Object.keys(Data).map(key => { return Data[key] });
    },
    AccountBankCard: state => {
        let Data = [];
        state.AccountBankCard.forEach(element => {
            Data.push({
                label: element.name,
                options: element.data.map((item, index) => {
                    return { text: item.value, value: item.id, disabled: item.disabled != 1 }
                })
            })
        });
        return Data
    },
    AccountBankCardList: state => {
        let classArr = ['credit ing', 'credit pass', 'credit returns'];
        let passArr  = ['store.under_review', 'store.examination_passed', 'store.review_returns'];
        let iconArr  = ['icon icon-review', 'icon icon-ok', 'icon icon-infomation'];
        return state.AccountBankCardList.map((item, index) => {
            let data     = item;
            data.class   = Object.keys(classArr).indexOf(data.disabled) >= 0 ? classArr[data.disabled] : 'credit ing';
            data.pass    = Object.keys(passArr).indexOf(data.disabled) >= 0 ? passArr[data.disabled] : 'store.under_review';
            data.icon    = Object.keys(iconArr).indexOf(data.disabled) >= 0 ? iconArr[data.disabled] : 'icon icon-review';
            return data;
        })
    },
    AccountBankCardData: state => (value) => {
        let Data = state.AccountBankCardList.find(element => element.id === value)
        return Data ? Data : { bank_name: '', bank_code: '', bank_account: '', bank_number: ''}
    },
    AccountDiscount: (state) => (value) => {
        let Data   = [];
        let TypeId = getPayTypeId(value)
        state.AccountDiscountData.forEach(element => {
            if (element.type == TypeId) {
                Data.push({value: element.id, text: element.name})
            }
        });
        return Data

        return []
    },
}

export const mutations = {
    _setTransferCaptchasImg: (state, data) => { state.TransferCaptchasImg = data; },
    _setBankList: (state, data) => { state.BankList = data; },
    _setBankCardList: (state, data) => { state.BankCardList = data; },
    _setAccountPay: (state, data) => {
        state.AccountPayLocal = data.Local;
        state.AccountPayThird = data.Third;
        state.DepositMessage  = data.Tip;
        state.DepositPage     = data.Page;
    },
    _setPayOtherList: (state, data) => { state.PayOtherList = data.list; },
    _setAccountWithdrawalData: (state, data) => { state.AccountWithdrawalData = data },
    _setAccountConversionData: (state, data) => { state.AccountConversionData = data },
    _setAccountDiscountData: (state, data) => { state.AccountDiscountData = data },
    SetAccountBankCard: (state, data) => {
        state.AccountBankCard = data.bank
        state.AccountBankCardList = data.list
    },
    SetAccountBankCardCount: (state, data) => {
        state.AccountBankCardCount = data.count
        state.AccountBankCardLimit = data.limit
    },
}

export const actions = {
    async _changeTransferCaptchas({ commit }) {
        commit("_setTransferCaptchasImg", '/api/TransferCaptchas?' + Math.random());
    },
    async _getBankList({ rootState, commit, dispatch }) {
        if (!rootState.NeedGetData.BankList) return
        const response = await this.$axios.post('/api/GetBankList');
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) {
                commit('_setBankList', response.data.list);
                commit('_setNeedGetData', { name: 'BankList', type: false }, { root: true });
            }
            return res
        })
    },
    async _getBankCardList({ rootState, dispatch, commit }) {
        if (!rootState.NeedGetData.BankCardList) return
        const response = await this.$axios.post('/api/GetBankCardList');
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) {
                commit('_setBankCardList', response.data);
                commit('_setNeedGetData', { name: 'BankCardList', type: false }, { root: true });
            }
            return res
        })
    },
    async _getPaySettingOptions({ rootState, dispatch, commit }) {
        if (!rootState.NeedGetData.PaySettingOptions) return
        const response = await this.$axios.post('/api/Pay/Options');
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) {
                commit('_setAccountPay', response.data);
            }
            return res
        })
    },
    async _getPayOtherList({ rootState, dispatch, commit }) {
        if (!rootState.NeedGetData.PayOtherList) return
        const response = await this.$axios.post('/api/Pay/OtherList');
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) {
                commit('_setPayOtherList', response.data);
            }
            return res
        })
    },
    async _getWithdrawalSettingOptions({ rootState, dispatch, commit }) {
        if (!rootState.NeedGetData.WithdrawalSettingOptions) return
        const response = await this.$axios.post('/api/Pay/Withdrawal');
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) {
                commit('_setAccountWithdrawalData', response.data);
            }
            return res
        })
    },
    async _getConversionSettingOptions({ rootState, dispatch, commit }) {
        if (!rootState.NeedGetData.ConversionSettingOptions) return
        const response = await this.$axios.post('/api/Pay/Conversion');
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) {
                commit('_setAccountConversionData', response.data);
            }
            return res
        })
    },
    async _getAccountDiscount({ rootState, dispatch, commit }) {
        if (!rootState.NeedGetData.AccountDiscount) return
        const response = await this.$axios.post('/api/Discount/AccountDiscount');
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) {
                commit('_setAccountDiscountData', response.data);
            }
            return res
        })
    },
    async BankCardFrontInsert({ dispatch }, data) {
        const response = await this.$axios.post('/api/BankCard/Insert', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            return res
        })
    },
    async InsertAccountPaymentSetting({ dispatch }, data) {
        const response = await this.$axios.post('/api/Payment/InsertSetting', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            return res
        })
    },
    async GetAccountBankCard({ commit, dispatch }, data) {
        const response = await this.$axios.post('/api/BankCard/AccountBankCard', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            commit('SetAccountBankCard', response.data);
            if (data.check === true && response.data.bank.length == 0) {
                this._vm.$bvModal.msgBoxOk(this.app.i18n.t('store.bind_bankcard'), {
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                }).then(value => {
                    this.$router.replace({ path: '/user/card' })
                }).catch(err => {

                })
            }
            return res
        })
    },
    async GetAccountBankCardCount({ commit, dispatch }, data) {
        const response = await this.$axios.post('/api/BankCard/AccountBankCardCount', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) {
                commit('SetAccountBankCardCount', response.data);
            }
            return res
        })
    },
    async AccountDeposit({ dispatch }, data) {
        const response = await this.$axios.post('/api/Transaction/AccountDeposit', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            return res ? response.data : res
        })
    },
    async AccountDepositCancel({ dispatch }, data) {
        const response = await this.$axios.post(`/api/Transaction/AccountDepositCancel/${data}`, );
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            return res ? response.data : res
        })
    },
    async AccountWithdrawal({ dispatch }, data) {
        const response = await this.$axios.post('/api/Transaction/AccountWithdrawal', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            return res
        })
    },
    async AccountConversion({ dispatch }, data) {
        const response = await this.$axios.post('/api/Transaction/AccountConversion', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            return res
        })
    },
    async GetAccountDepositDetail({ dispatch }, data) {
        const response = await this.$axios.post(`/api/Transaction/AccountDepositDetail/${data}`);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            return res ? response.data : res
        })
    },
    async UpdateAccountDepositDetailImg({ dispatch }, { order_id, data }) {
        const response = await this.$axios.post(`/api/Transaction/UpdateAccountDepositDetailImg/${order_id}`, data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            return res ? response.data : res
        })
    },
    GetPayTypeName({ dispatch }, data) {
        return getPayTypeName(this.app.i18n.t(data))
    },
}


function getPayTypeName(data) {
    switch (data) {
        case 'BankTransfer':
            return 'store.money_transfer'
            break;
        case 'LinePay':
            return 'store.LinePay'
            break;
        case 'JkoPay':
            return 'store.jkopay'
            break;
    }
    return data
}

function getPayTypeId(data) {
    switch (data) {
        case 'BankTransfer':
            return '1'
            break;
        case 'LinePay':
            return '1'
            break;
        case 'JkoPay':
            return '1'
            break;
        default:
            return data ? data.toString() : data;
    }
    return '2'
}
