/**
*
* @param {*} UA ,就是userAgent
* @returns  type: 設備類型
*           env: 訪問環境(微信/微博/qq)
*           masklayer: 就是給外部拿到判斷是否顯示遮罩層的,一些特殊環境要引導用戶到外部去打開訪問
*/
function isWechat(UA) {
	return /MicroMessenger/i.test(UA) ? true : false;
}
function isWeibo(UA) {
	return /Weibo/i.test(UA) ? true : false;
}
function isQQ(UA) {
	return /QQ/i.test(UA) ? true : false;
}
function isMoible(UA) {
	return /(Android|webOS|iPhone|iPad|iPod|tablet|BlackBerry|Mobile)/i.test(UA) ? true : false;
}
function isIOS(UA) {
	return /iPhone|iPad|iPod/i.test(UA) ? true : false;
}
function isAndroid(UA) {
	return /Android/i.test(UA) ? true : false;
}
function isMAC(UA) {
	return /Macintosh/i.test(UA) ? true : false;
}
function isSDK(UA) {
	return /SDK/i.test(UA) ? true : false;
}

export function deviceType(UA) {
	if (isMoible(UA)) {
		if (isIOS(UA)) {
			if (isWechat(UA)) {
				return { type: "ios",　env: "wechat",　masklayer: true };
			}
			if (isWeibo(UA)) {
				return {　type: "ios",　env: "weibo",　masklayer: true };
			}
			if (isQQ(UA)) {
				return { type: "ios", env: "qq", masklayer: true };
			}
			if (isSDK(UA)) {
				return { type: "ios", env: "sdk", masklayer: true };
			}

			return { type: "ios" };
		}
		
		if (isAndroid(UA)) {
			if (isWechat(UA)) {
				return { type: "android", env: "wechat", masklayer: true };
			}
			if (isWeibo(UA)) {
				return { type: "android", env: "weibo", masklayer: true };
			}
			if (isQQ(UA)) {
				return { type: "android", env: "qq", masklayer: true };
			}
			if (isSDK(UA)) {
				return { type: "android", env: "sdk", masklayer: true };
			}

			return { type: "android" };
		}

		return { type: "mobile" };
	} else {
		if (isMAC(UA)) {
			return { type: "pc", env: "mac", masklayer: true };
		}

		return { type: "pc" };
	}
}