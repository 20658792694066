
export const state = () => ({
    PlacementList: [],
    PlacementTableSetting: { Loading: true, PageSetting: { Total: 1, Current: 1, PageSize: 999999 } },
    PlacementCloseId: [],
    PlacementShow: true,
})

export const getters = {
}

export const mutations = {
    setTableList: (state, { name, data }) => {
        state[`${name}List`] = data.list;
        state[`${name}TableSetting`].PageSetting.Total = data.total
    },
    setTableLoading: (state, { name, value }) => {
        state[`${name}TableSetting`].Loading = value;
    },
    setTablePage: (state, { name, type, num }) => {
        state[`${name}TableSetting`][type] = num;
    },
    setPlacementCloseId: (state, id) => {
        state.PlacementCloseId.push(id);
    },
    setPlacementShow: (state, data) => {
        state.PlacementShow = data;
    },
    
    
}

export const actions = {
    async getPlacementList({ rootState, state, dispatch, commit }, SelectForm) {
        if (!rootState.NeedGetData.PlacementList) return;

        let name = 'Placement';
        commit('setTableLoading', { name, value: true});
        let PageSetting = state.PlacementTableSetting.PageSetting;
        const response = await this.$axios.post('/api/Placement/List', { SelectForm, PageSetting });
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) {
                commit('setTableList', { name, data: response.data });
                commit('_setNeedGetData', { name: 'PlacementList', type: false }, { root: true });
            }
            commit('setTableLoading', { name, value: false});
            return res
        })
    }
}