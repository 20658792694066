
export const state = () => ({
    AccountCard: {}
})

export const getters = {
}

export const mutations = {
    SetAccountCard: (state, data) => {
        state.AccountCard = data.info
    }
}

export const actions = {
    async GetAccountCard({ commit, dispatch }, data) {
        const response = await this.$axios.post('/api/AccountCard/Info', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            commit('SetAccountCard', response.data);

            if (data.check === true && (Object.keys(response.data.info).length == 0 || response.data.info.flag != '1')) {
                this._vm.$bvModal.msgBoxOk(this.app.i18n.t('store.bind_usercard'), {
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                }).then(value => {
                    this.$router.replace({ path: '/user/user' })
                });

                return false;
            }

            return res;
        });
    },
    async InsertAccountCard({ dispatch }, data) {
        const response = await this.$axios.post('/api/AccountCard/Insert', data);
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            return res;
        });
    },
}