import Vue from 'vue'
// 时间格式化
export function formatDate(date, fmt) {
  let newDate = new Date(date)
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (newDate.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  let o = {
    'M+': newDate.getMonth() + 1,
    'd+': newDate.getDate(),
    'h+': newDate.getHours(),
    'm+': newDate.getMinutes(),
    's+': newDate.getSeconds()
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
    }
  }
  return fmt
}

export function localeDate(value) {
  if (!value || value === '' || value.length < 10)
    return '';

  let dateArr = value.split(/[- :]/);  
  let newDate = new Date(dateArr[0], dateArr[1]-1, dateArr[2], dateArr[3], dateArr[4], dateArr[5]);
  return newDate.toLocaleString();
}

export function localeDateString(value) {
  if (!value || value === '' || value.length < 10)
    return '';

  let dateArr = value.split(/[- :]/);
  let newDate = new Date(dateArr[0], dateArr[1]-1, dateArr[2], dateArr[3], dateArr[4], dateArr[5]);
  return newDate.toLocaleDateString();
}

export function useLocaleDate(Value, Locale) {
  if (!Value || Value === '' || Value.length < 10)
    return '';

  let DateArr = Value.split(/[- :]/);  
  let NewDate = new Date(DateArr[0], DateArr[1]-1, DateArr[2], DateArr[3], DateArr[4], DateArr[5]);
  return NewDate.toLocaleString(Locale);
}

export function currency(value) {
  if (!value)
    return 0
  else
    return parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export function currencyInt(value) {
  if (!value)
    return 0
  else
    return parseInt(value).toString().replace(/^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/, function (all, pre, groupOf3Digital) { return pre + groupOf3Digital.replace(/\d{3}/g, ',$&'); });
}

export function bankNumber(value) {
  return value.toString().replace(/(\d{4})(?=\d)/g, '$1 ');
}

let filters = {
  formatDate,
  localeDate,
  localeDateString,
  useLocaleDate,
  currency,
  currencyInt,
  bankNumber
}


Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

export default filters